<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">APPROVED FOR NEW MONTH</h4>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text class="mt-4">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">
                ID
              </th>
              <th class="text-center text-uppercase">
                Branch Code
              </th>
              <th class="text-center text-uppercase">
                Status
              </th>
              <th class="text-center text-uppercase">
                Transaction Month
              </th>
              <th class="text-center text-uppercase">
                Action
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="item in branch_items"
              :key="item.id"
            >
              <td>{{ item.id }}</td>
              <td class="text-center">
                {{ item.branch_code }}
              </td>
              <td class="text-center">
                {{ item.can_new_month===0?'ENCODING':item.can_new_month===1?'CAN NEW MONTH':'PENDING
                REQUEST' }}
              </td>
              <td class="text-center">
                {{ item.transaction_month.month_of }}
              </td>
              <td class="text-center">
                <div
                  v-if="item.can_new_month===2">
                  <v-icon
                    v-if="!saving"
                    class="mr-2"
                    color="success"
                    @click="approved_requested(branch_items[branch_items.map(function(x) {return x.id; }).indexOf(item.id)])"
                  >
                    {{icons.mdiCheck}}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-else></v-progress-circular>
                </div>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiCheck} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      branch_items: [],
      saving: false,
    };
  };
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiCheck,
        },
      }
    },
    created() {
      this.request_data()
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
      ...mapGetters('authentication', ['branch_id']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('branch_information', ['get_branches_active', 'approve_for_new_month']),
      reset() {
        Object.assign(this.$data, initialState())
        this.request_data()
      },
      request_data() {
        this.get_branches_active()
          .then(response => {
            this.branch_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      approved_requested(datum) {
        this.saving = true
        this.alert = false
        const data = new FormData()
        data.append('id', datum.id);
        this.approve_for_new_month(data)
          .then(response => {
            this.change_snackbar({
              show: true,
              color: 'success',
              text: response.data,
            })
            this.reset()
          })
          .catch(error => {
            this.alert_req = true
            this.alert_message_req = error
            this.saving_req = false
          })
      },
    }
  }
</script>
